import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/vkuramshin/p/www/tkcrm/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "начало-работы-с-tkcrm"
    }}>{`Начало работы с TKCRM`}</h1>
    <p><strong parentName="p">{`TKCRM`}</strong>{` - модульная платформа, которая занимается решением вопросов по автоматизации логистики и мониторингу удаленных объектов.`}</p>
    <p>{`В данном рукводстве по использованию мы постараемся максимально подробно описать взаимодествие с системой, расскажем как настроить её под Ваши конкретные задачи, а так же опубликуем готовые примеры.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Документация находится в разработке и постоянно дополняется. Мы усердно работаем, чтобы описать весь имеющийся функционал как можно скорее.`}</p>
    </blockquote>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/monitoring"
        }}>{`Мониторинг`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      